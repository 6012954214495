// ResellerRegistration.tsx or ResellerRegistration.jsx
import React, { useState } from 'react';
import {
    IonCol,
    IonContent,
    IonImg,
    IonRow,
    IonTitle
} from '@ionic/react';
import './ResellerRegistration.scss'; // Import the SCSS file
import { Link, useHistory } from 'react-router-dom';
import { appSubDir } from '../configs/app';
import { AuthContextProvider } from '../services/auth-context.service';
import AuthBody from '../components/Auth/AuthBody';
import "@ionic/react/css/ionic.bundle.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import Swiper modules
import { Pagination, Autoplay } from 'swiper';

const ResellerRegistration: React.FC = () => {
    const [success, setSuccess] = useState(false)
    const history = useHistory()
    return (
        < IonContent fullscreen id="reseller-registration" >

            {/* Main Sections */}
            < IonRow >
                <IonCol
                    sizeXl="10"
                    offsetXl="1"
                    sizeLg="10"
                    offsetLg="1"
                    className="no-padding bg-content"
                >
                    {/* Custom Header Section */}
                    <div className="header">
                        <Link to={appSubDir} className='brand'>
                            <IonImg src="assets/icon/logo-rectangle.webp" alt='Brand Logo' className="header-logo" />
                            <h1>Bbazar</h1>
                        </Link>
                        <h1 className="header-title">রিসেলার প্রোগ্রামে আপনাকে স্বাগতম </h1>
                    </div>
                    <IonRow className="ion-align-items-center">
                        <IonCol sizeLg="6" sizeSm="12">
                            <Swiper
                                modules={[Pagination, Autoplay]}
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 5000, disableOnInteraction: false }}
                                loop
                                className="mySwiper"
                            >
                                {[1, 2, 3].map((item, index) => (<SwiperSlide key={index}>
                                    <IonImg src={appSubDir + `assets/img/reseller-registration-${item}.webp`} alt={`Slider ${item}`} />
                                </SwiperSlide>))}
                            </Swiper>
                        </IonCol>
                        {success ? <IonCol sizeLg="6" sizeMd="12" sizeXs='12' className='form-column'>
                            <IonTitle >রিসেলার রেজিস্ট্রশন সম্পন্ন হয়েছ</IonTitle>
                            <p>আমরা আপনার আবেদনটি জাচাই বাছাই করছি । খুব শীঘ্রই আপনার কাছে কনফার্মেশন মেসসজ যাবে ।</p>
                        </IonCol> : <IonCol sizeLg="6" sizeMd="12" sizeXs='12' className='form-column'>
                            <IonTitle >রিসেলার রেজিস্ট্রশন </IonTitle>
                            <p>বিবাজার রিসেলার প্রোগ্রামে রেজিস্ট্রশন করে এখনই আপনার অনলাইন বিজনেস শুরু করুন কোন পুঁজি ছাড়াই</p>
                            <AuthContextProvider authData={{
                                role_id: 2,
                                screen: "registration",
                                onlyForm: true,
                                onSignupSuccess: () => {
                                    history.push(appSubDir)
                                }
                            }}>
                                <AuthBody />
                            </AuthContextProvider>
                        </IonCol>}
                    </IonRow>
                </IonCol>
            </IonRow >
        </IonContent >
    );
};

export default ResellerRegistration;
