import { appSubDir } from '../../configs/app';
import { HomeItem } from '../../types/home.type';
import { Link } from 'react-router-dom';


type Props = {
    item: HomeItem
    children: JSX.Element,
}
const HomeItemLink: React.FC<Props> = ({ item, children }) => {
    if (item.category_id) {
        if (item.sub_category_id) {
            return <Link to={appSubDir + "categories/" + item.category_id + "/sub-categories/" + item.sub_category_id}>
                {children}
            </Link>
        } else {
            return <Link to={appSubDir + 'categories/' + item.category_id}>
                {children}
            </Link>
        }
    } else if (item.url) {
        return <a href={item.url} target='_blank'>
            {children}
        </a>
    } else {
        return <>
            {children}
        </>
    }
};

export default HomeItemLink;
