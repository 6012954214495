import { useEffect, useState } from 'react';
import { fetchProducts,getProducts } from '../../services/products/products.service';
import ProductsGrid from '../Products/ProductsGrid';
import { appSubDir } from '../../configs/app';

const params = new URLSearchParams("new_arrivals=new_arrivals&random_order=true&img=web&items_per_page=12")
type Props = {
    excluded_product:number
}
const NewArrivalComponent: React.FC<Props> = ({excluded_product}) => {
    params.set('excluded_product',excluded_product.toString())
    const localProduct = getProducts(params)
    const [items, setItems] = useState(localProduct?.data)
    useEffect(() => {
        fetchProducts(params).then(product => {
            if (product) {
                setItems(product.data)
            }
        })
    }, [])
    if(!items){
        return null
    }
    return (
        <ProductsGrid title='New Arrivals' products={items} routerLink={appSubDir+"new-arrival"}/>
    );
};

export default NewArrivalComponent;
