import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonItem, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import { fetchOrders, getOrders } from '../../services/account/active-order-lists.service';
import { getBadgeColor } from '../../services/account/invoices.service';
import { useHistory } from 'react-router';
import { fetchDashboardData, getDashboardData } from '../../services/account/reseller-dashboard.service';
import './ResellerDashboard.scss'

const BDT = new Intl.NumberFormat('en-IN');
type CardProps = {
    value: string
    title: string
    color: string
}
const CustomCard: React.FC<CardProps> = ({ value, title, color }) => {
    return <IonCol sizeLg='4' sizeMd='4' sizeSm='4' sizeXs='6'>
        <IonCard color={color}>
            <IonCardHeader>
                <IonCardTitle><h1 style={{ textAlign: "center", fontSize: 50, fontWeight: 600 }}>{value}</h1></IonCardTitle>
                <IonCardSubtitle><h6 style={{ textAlign: "center" }}>{title}</h6></IonCardSubtitle>
            </IonCardHeader>
        </IonCard>
    </IonCol>
};
const ActiveOrderList: React.FC = () => {
    const history = useHistory()
    const [loading, setLoadling] = useState(false)
    const response = getOrders()
    const [items, serDashboardData] = useState(response)
    useEffect(() => {
        loadData()
    }, [])
    function loadData() {
        const local = getOrders()
        if (local) {
            serDashboardData(local)
        } else {
            setLoadling(true)
        }
        fetchOrders().then(serDashboardData).finally(() => setLoadling(false))
    }
    function showDetails(id: string) {
        history.push(appSubDir + "invoices/" + id)
    }
    return (
        <>
            {!items && !loading && <div className='text-center'>
                <h2 style={{ marginTop: 100, marginBottom: 100 }}>No Active Orders Found</h2>
            </div>}
            {items && <div style={{ maxHeight: 500, overflowY: 'auto' }}>

                <h2 className='text-center'>Active Orders</h2>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th className='mobile-invisible'>Date</th>
                            <th>Sub Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            if (item.status)
                                return <tr style={{ cursor: "pointer" }} key={index} onClick={() => showDetails(item.id)}>
                                    <td className='text-center'>#{item.id.toString().padStart(6, '0')}</td>
                                    <td className='text-center mobile-invisible'>{new Date(Date.parse(item.created_at)).toDateString()}</td>
                                    <td className='text-end'>{BDT.format(parseFloat(item.total))}/=</td>
                                    <td className='text-center'><IonBadge color={getBadgeColor(item.status)}>{item.status}</IonBadge></td>
                                </tr>
                        })}
                    </tbody>
                </table>
            </div>}
        </>
    );
};
const ResellerDashboard: React.FC = () => {
    const [loading, setLoadling] = useState(false)
    const [dashboardData, serDashboardData] = useState(getDashboardData())
    useEffect(() => {
        loadData()
    }, [])
    function loadData() {
        const local = getDashboardData()
        if (local) {
            serDashboardData(local)
        } else {
            setLoadling(true)
        }
        fetchDashboardData().then((res) => {
            if (res) {
                serDashboardData(res)
            }
        }).finally(() => setLoadling(false))
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        <div className='title-bar' style={{ marginTop: 60, marginBottom: 50 }}>
                            <h1>My Dashboard</h1>
                        </div>
                        {dashboardData && <IonGrid>
                            <IonRow>
                                <CustomCard color='warning' title='Total Order' value={dashboardData.total_order} />
                                <CustomCard color='tertiary' title='Total Withdrawn' value={BDT.format(parseInt(dashboardData.total_withdrawn)) + " /="} />
                                <CustomCard color='success' title='Current Balance' value={BDT.format(parseInt(dashboardData.current_balance)) + " /="} />
                                <CustomCard color='danger' title='Last Month Income' value={BDT.format(parseInt(dashboardData.last_month_income)) + " /="} />
                                <CustomCard color='light' title='This Month Income' value={BDT.format(parseInt(dashboardData.this_month_income)) + " /="} />
                                <CustomCard color='primary' title='Reseller Rank' value={dashboardData.reseller_rank} />
                                <CustomCard color='warning' title='Pending Order' value={dashboardData.pending_order} />
                                <CustomCard color='tertiary' title='Delivered Order' value={dashboardData.delivered_order} />
                                <CustomCard color='success' title='Complete Order' value={dashboardData.complete_order} />
                            </IonRow>
                        </IonGrid>}
                        <div id='dashboard-links'>
                            <h1>Important Links</h1>
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeLg='4' sizeMd='4' sizeSm='4' sizeXs='6'>
                                        <IonButton routerLink={appSubDir+"referral-dashboard"} color='danger' expand='block'>Refer & Earn</IonButton>
                                    </IonCol>
                                    <IonCol sizeLg='4' sizeMd='4' sizeSm='4' sizeXs='6'>
                                        <IonButton routerLink={appSubDir+"transactions"} color='light' expand='block'>Transaction History</IonButton>
                                    </IonCol>
                                    <IonCol sizeLg='4' sizeMd='4' sizeSm='4' sizeXs='6'>
                                        <IonButton routerLink={appSubDir+"invoices"} color='primary' expand='block'>Orders</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default ResellerDashboard;
