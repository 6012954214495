import React, { createContext, useContext, useState } from 'react';
import AuthData from '../types/auth-data.type';

export const defaultAuthData = {
    role_id: 3,
    screen: 'login'
} as AuthData;

const AuthContext = createContext(defaultAuthData);
const AuthContextUpdater = createContext((newData: AuthData) => { });

type Props = {
    authData?: AuthData
    children: JSX.Element
}
export const AuthContextProvider: React.FC<Props> = ({ authData, children }) => {
    const [data, SetData] = useState(authData ? authData : defaultAuthData);
    const SetAuthData = (newData: AuthData) => {
        SetData(newData)
    }
    return <AuthContext.Provider value={data}>
        <AuthContextUpdater.Provider value={SetAuthData}>
            {children}
        </AuthContextUpdater.Provider>
    </AuthContext.Provider>
};

export const useAuthContext = () => {
    const store = useContext(AuthContext);
    if (!store) {
        throw new Error('useAuthContext must be used within a AuthContextProvider.');
    }
    return store;
};
export const useAuthContextUpdater = () => {
    return useContext(AuthContextUpdater);
};
