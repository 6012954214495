import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Product from '../../types/product.type';
import ProductsGrid from './ProductsGrid';
import { fetchProducts,getProducts } from '../../services/products/products.service';
type Props = {
    product: Product
    title: string
}
const params = new URLSearchParams(`?items_per_page=12&img=web`)
const SubCategoryProductsGrid: React.FC<Props> = ({ product, title }) => {
    params.set('subcategory_id',product.subcategory_id.toString())
    params.set('excluded_product',product.id.toString())
    const localProduct = getProducts(params)
    const [items, setItems] = useState(localProduct?.data)
    function fetchData() {
        fetchProducts(params).then(product => {
            if (product) {
                setItems(product.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [])
    if (!items || items.length < 1) {
        return null
    }
    return (
        <ProductsGrid title={title} products={items} routerLink={appSubDir + "categories/" + product.category_id + "/sub-categories/" + product.subcategory_id} />
    );
};

export default SubCategoryProductsGrid;
