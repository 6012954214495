import { useEffect } from 'react';
import { useAppContext } from '../services/app-context.service';
import { attachReferrer, fetchMyReferrer, getSavedReferralCode, unsaveReferralCode } from '../services/referal-code.service';
type Props = {
    children: JSX.Element,
    publicRoutes: JSX.Element,
};
const AuthGuard: React.FC<Props> = ({ publicRoutes, children }) => {
    const data = useAppContext()
    const userId = data.user.id
    useEffect(() => {
        const timer = setTimeout(() => {
            if (data.user.id && data.user.role_id === 2) {
                const code = getSavedReferralCode()
                if (code) {
                    fetchMyReferrer().then((res) => {
                        if (res.data.referrer) {
                            unsaveReferralCode()
                        } else {
                            attachReferrer(code).then(() => {
                                unsaveReferralCode()
                            })
                        }
                    })
                }
            }
        }, 10)
        return () => {
            clearTimeout(timer)
        }
    }, [userId])
    if (data.user.token) {
        return <>{children}</>
    } else {
        return <>{publicRoutes}</>
    }
};

export default AuthGuard;
