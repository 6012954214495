import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonModal, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { appSubDir, baseUrl } from '../../configs/app';
import './QuickView.scss'
import { useRef, useState } from 'react';
import { fetchProduct, getLocalProduct } from '../../services/products/product.service';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import ZoomableImg from '../ZoomableImg';
import HtmlParser from '../HtmlParser';
import { useAppContext } from '../../services/app-context.service';
import QuickViewAddToCart from './QuickViewAddToCart';
import Product from '../../types/product.type';
import { getProductUrl } from '../../functions/product.functions';
type Props = {
    title: string
    productId: number
    children: JSX.Element
}
const BDT = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'BDT'
});
const QuickView: React.FC<Props> = ({ title, productId, children }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    const localProduct = getLocalProduct(productId)
    const [item, setItem] = useState(localProduct)
    const [loading, setLoading] = useState(!localProduct)
    const history = useHistory();
    function onBackButtonPress(e: PopStateEvent) {
        e.preventDefault()
        onClose()
    }
    function open(e: any) {
        e.preventDefault()
        // Push a new entry to the history stack
        history.push(window.location.pathname + '#modal');
        window.addEventListener('popstate', onBackButtonPress);
        modal.current?.present()
        if (!localProduct) {
            setLoading(true)
        }

        fetchProduct(productId).then(product => {
            if (product) {
                setItem(product)
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    function onClose() {
        modal.current?.dismiss()
        window.removeEventListener('popstate', onBackButtonPress);
    }

    return <>
        <a href={getProductUrl(productId, item ? item.slug : encodeURIComponent(title))} className='quick-view-card' onClick={open}>{children}</a>
        <IonModal ref={modal} className='quick-view' id={item ? 'quick-view-' + item.id : undefined}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>{title}</IonTitle>
                    {window.screen.width > 425 && <IonButton onClick={onClose} slot='end' fill='clear'>Close</IonButton>}
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                {loading && <div className='box-center'><IonSpinner /></div>}
                {!loading && !item && <div className='box-center'><IonText><h1>Something Went Wrong</h1></IonText></div>}
                {!loading && item && <>
                    <IonRow>
                        <IonCol sizeXs='12' sizeMd='6'>
                            <Swiper
                                autoplay={{
                                    delay: 3000
                                }}
                                zoom={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}>
                                <SwiperSlide>
                                    <ZoomableImg src={baseUrl + item.image} />
                                </SwiperSlide>
                                {!!item.gallery_1 && <SwiperSlide>
                                    <ZoomableImg src={baseUrl + item.gallery_1} />
                                </SwiperSlide>}
                                {!!item.gallery_2 && <SwiperSlide>
                                    <ZoomableImg src={baseUrl + item.gallery_2} />
                                </SwiperSlide>}
                            </Swiper>
                        </IonCol>
                        <IonCol sizeXs='12' sizeMd='6'>
                            <div style={{ padding: 10 }}>
                                <div className='pricing'>
                                    <IonText className='sale' color='primary'>{BDT.format(item.sale_price)}</IonText>
                                    <IonText className='regular'>{BDT.format(item.regular_price)}</IonText>
                                </div>
                                {item.quantity > 0 ?
                                    <IonText className='stock'>Only <b>{item.quantity}</b> Item{item.quantity > 1 ? "s" : ''} left</IonText> :
                                    <IonText className='stock' color='danger'>Out Of Stock</IonText>}
                                <div className='quantity'>
                                    <QuickViewAddToCart item={item} modalRef={modal} />
                                    <IonButton routerLink={getProductUrl(item.id, item.slug)} fill='outline'>See More Details</IonButton>
                                </div>
                                <div className='cat'>
                                    <h6>Categories</h6>
                                    {item.category_id > 0 && <Link to={appSubDir + "categories/" + item.category_id}>{item.category_title}</Link>}
                                    {item.subcategory_id > 0 && <FontAwesomeIcon icon={faAngleRight} />}
                                    {item.subcategory_id > 0 && <Link to={getProductUrl(item.id, item.slug)}>{item.sub_category_title}</Link>}
                                </div>
                                <div className='cat'>
                                    <h6>Brand</h6>
                                    {item.brand_id > 0 && <Link to={appSubDir + "brands/" + item.brand_id}>{item.brand_name}</Link>}
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div style={{ padding: 10 }}>
                                <h6>Description</h6>
                                <HtmlParser html={item.description} />
                            </div>
                        </IonCol>
                    </IonRow>
                </>}
            </IonContent>
            {window.screen.width <= 425 && <IonFooter>
                <IonButton onClick={onClose} expand='block' fill='clear'>Back</IonButton>
            </IonFooter>}
        </IonModal>
    </>
};

export default QuickView;
