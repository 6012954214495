export const googleApiKey = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
// Testing
// export const facebookPixelId = '525627326503432'
// export const googleTagManagerId = 'GTM-NL5545CL'
export const googleAnalyticsMeasurmentId = 'G-7ZK5HY5RJC'

// Production
export const facebookPixelId = '942569966684494'
export const googleTagManagerId = 'GTM-NL5545CL'
// export const googleAnalyticsMeasurmentId = 'G-STGB4NTQDJ'
