import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonItemGroup, IonLabel, IonRow, IonSkeletonText, IonSpinner, useIonToast } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir, baseUrl } from '../../configs/app';
import { fetchOrders, getOrders } from '../../services/account/active-order-lists.service';
import { getBadgeColor } from '../../services/account/invoices.service';
import { useHistory } from 'react-router';
import { fetchDashboardData, getDashboardData } from '../../services/account/reseller-dashboard.service';
import { copyOutline } from 'ionicons/icons';
import { fetchReferralCode, fetchReferralDashboard, rcParamKey } from '../../services/referal-code.service';

type ApiResponse = {
    referralCode: string
    referredBy: number
    referralCount: number
}
const ReferralDashboard: React.FC = () => {
    const [loading, setLoadling] = useState(false)
    const [dashboardData, serDashboardData] = useState<ApiResponse | null>(null)
    const referralLink = dashboardData ? window.location.href.replace(window.location.pathname, '') + appSubDir + "reseller-registration/?" + rcParamKey + "=" + dashboardData?.referralCode : null
    const [presentToast] = useIonToast();
    useEffect(() => {
        const timer = setTimeout(loadData, 10)
        return () => {
            clearTimeout(timer)
        }
    }, [])
    function loadData() {
        setLoadling(true)
        fetchReferralDashboard().then((res) => {
            const data = res.data as ApiResponse | null
            serDashboardData(data)
        }).finally(() => setLoadling(false))
    }
    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
            presentToast({
                message: 'Copied To Clipboard',
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        })
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {loading ? <div style={{ textAlign: "center" }}><IonSpinner /></div> : <IonGrid>
                            <IonRow>
                                <IonCol sizeXl='6' sizeLg="12" sizeMd='12' sizeXs='12' sizeSm="12">
                                    {/* image size 900x450 */}
                                    <IonImg style={{ margin: "0 auto" }} src={appSubDir + "assets/img/reseller-dashboard.webp"} />
                                </IonCol>
                                <IonCol sizeXl='6' sizeLg="12" sizeMd='12' sizeXs='12' sizeSm="12">
                                    <IonRow>
                                        <IonCol sizeLg="6" sizeMd='6' sizeXs='12' sizeSm="12">
                                            <IonCard color='danger'>
                                                <IonCardHeader>
                                                    <IonCardTitle><h1 style={{ textAlign: "center", fontSize: 50, fontWeight: 600 }}>{dashboardData?.referralCount}</h1></IonCardTitle>

                                                    <IonCardSubtitle><h6 style={{ textAlign: "center" }}>Total Referrals</h6></IonCardSubtitle>
                                                </IonCardHeader>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol sizeLg="6" sizeMd='6' sizeXs='12' sizeSm="12">
                                            <IonCard color='tertiary'>
                                                <IonCardHeader>
                                                    <IonCardTitle><h1 style={{ textAlign: "center", fontSize: 50, fontWeight: 600 }}>{dashboardData?.referralCode}</h1></IonCardTitle>
                                                    <IonCardSubtitle><h6 style={{ textAlign: "center" }}>Referral Code</h6></IonCardSubtitle>
                                                    <IonButton onClick={() => dashboardData && copyToClipboard(dashboardData.referralCode)} style={{ position: 'absolute', top: 10 }} fill='clear'><IonIcon icon={copyOutline} /></IonButton>
                                                </IonCardHeader>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol sizeLg="12" sizeMd='12' sizeXs='12' sizeSm="12">
                                            <IonCard>
                                                <IonCardHeader>
                                                    <IonCardTitle>My Referral Details</IonCardTitle>
                                                </IonCardHeader>
                                                <IonCardHeader>
                                                    <IonItemGroup>
                                                        <IonItem>
                                                            <IonLabel position="floating">Referral Link</IonLabel>
                                                            <IonInput readonly value={referralLink} />
                                                            <IonButton onClick={() => referralLink && copyToClipboard(referralLink)} slot='end' fill='clear'><IonIcon icon={copyOutline} /></IonButton>
                                                        </IonItem>

                                                    </IonItemGroup>
                                                </IonCardHeader>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default ReferralDashboard;
