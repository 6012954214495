import ReactPixel, { Options } from 'react-facebook-pixel';
import { facebookPixelId } from '../configs/config.private';
import TagManager from 'react-gtm-module';
import { googleTagManagerId } from '../configs/config.private';

const options: Options = {
    autoConfig: false,   // set pixel's autoConfig
    debug: false,       // enable logs
}

ReactPixel.init(facebookPixelId, undefined, options);


const tagManagerArgs = {
    gtmId: googleTagManagerId  // Your GTM ID
};

TagManager.initialize(tagManagerArgs);

export function trackEvent(event:any,data?:any){
    ReactPixel.track(event, data);
    TagManager.dataLayer({
        dataLayer: {
            event: event,
            ...data
        }
    });
}
