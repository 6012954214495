// Save Referral Code if found in the url

import { apiBaseUrl } from "../configs/app";
import CryptoService from "./crypto.services";
import loginService from "./loginService";
const debug = true
const referralCodeKey = CryptoService.hash('referal-code-key')
export const rcParamKey = "ui"
function removeRCfromUrl() {
    // Create a new URL object from the current window location
    const url = new URL(window.location.href);

    // Use URLSearchParams to parse the query string
    const params = new URLSearchParams(url.search);

    // Remove the 'age' parameter
    params.delete(rcParamKey);

    // Update the URL's search property
    url.search = params.toString();

    // Optionally, update the browser's address bar without reloading the page
    window.history.replaceState({}, '', url);
}
export function getSavedReferralCode() {
    const encryptedRC = window.localStorage.getItem(referralCodeKey)
    if (encryptedRC) {
        return CryptoService.decrypt(encryptedRC)
    }
    return null
}
export function unsaveReferralCode() {
    debug && console.log("Referral Status : Code Removed")
    window.localStorage.removeItem(referralCodeKey)
}
export function saveReferralCode() {
    // Create a new URL object from the current window location
    const url = new URL(window.location.href);

    // Use URLSearchParams to parse the query string
    const params = new URLSearchParams(url.search);

    // Get specific query parameters
    const referralCode = params.get(rcParamKey);

    if (referralCode) {
        window.localStorage.setItem(referralCodeKey, CryptoService.encrypt(referralCode))
        debug && console.log("Referral Status : Code ", referralCode)
        removeRCfromUrl()
    }
}

export function attachReferrer(code: string) {
    return loginService.privatePutApiCall(apiBaseUrl + "profile/referal-code", { code }).then((res) => {
        debug && console.log("Referral Status : Applied ", res)
        return res
    })
}
export function fetchReferralDashboard() {
    return loginService.privateGetApiCall(apiBaseUrl + "profile/referal-dashboard")
}
export function fetchReferralCode() {
    return loginService.privateGetApiCall(apiBaseUrl + "profile/referal-code")
}
export function fetchMyReferrer() {
    return loginService.privateGetApiCall(apiBaseUrl + "profile/my-referrer")
}
