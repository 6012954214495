import {
    IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel,
    IonNote, IonSpinner, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Footer from '../Footer';
import { SignUpFormData } from '../../../types/auth-data.type';
import loginService from '../../../services/loginService';
import { useAuthContext, useAuthContextUpdater } from '../../../services/auth-context.service';
import './Form.scss';
import { getSavedReferralCode } from '../../../services/referal-code.service';

const Form: React.FC = () => {
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
    const [presentToast] = useIonToast();
    function onUpdateReCaptcha(token: any) {
        if (token) {
            setReCaptchaToken(token)
        } else {
            setReCaptchaToken(null)
        }
    }
    function validator(values: SignUpFormData) {
        const errors = {} as SignUpFormData;
        if (!values.name) {
            errors.name = 'Required';
        } else if (
            values.name.includes("@")
        ) {
            errors.name = 'Invalid Name address';
        }

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (
            values.phone.length != 11
        ) {
            errors.phone = '11 Digit Required';
        } else if (
            !values.phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.phone = 'Invalid Phone Number';
        }


        if (!values.password) {
            errors.password = 'Required';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Password Don't Match";
        }
        return errors;
    }
    function onSubmit(values: SignUpFormData, formikHelpers: FormikHelpers<SignUpFormData>) {
        if (!reCaptchaToken) {
            console.error("ReCaptcha Token Was not set")
            return
        }
        const { setSubmitting } = formikHelpers
        // console.log(values);

        // reCaptchaRef.current?.reset()
        const referralCode = getSavedReferralCode()
        if (referralCode) {
            values.referral_code = referralCode
        }

        setSubmitting(true);
        loginService.requestSignUpOtp(values, authData.role_id.toString(), reCaptchaToken).then((payload) => {
            setAuthData({ ...authData, signUpFormData: values, signUpPayload: payload, screen: 'otp' })
        }).catch(errPromise => {
            errPromise.then((errResponse: any) => {
                const messages = [];
                if (errResponse.message && Object.values(errResponse.message).length > 0) {
                    messages.push(Object.values(errResponse.message).map((item) => {
                        const temp = item as string[]
                        return temp.join(",")
                    }).join(","))
                } else if (errResponse.message) {
                    messages.push(errResponse.message)
                } else {
                    messages.push("Something Went Wrong")
                }
                presentToast({
                    message: messages.join(","),
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            })
        }).finally(() => {
            setSubmitting(false);
            setReCaptchaToken(null)
        })
    }
    const formBody = <div className='sign-up-form-container'>
        <Formik
            initialValues={{
                // name: "Custoemr One",
                // phone: "01660000001",
                // email: "cus.1@bbazar.test",
                // password: "12345678",
                // confirmPassword: "12345678"
            } as SignUpFormData}
            validate={validator}
            onSubmit={onSubmit}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                    <IonItem className={errors.name && touched.name ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Name</IonLabel>
                        <IonInput name='name'
                            onIonChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name} />
                        {errors.name && touched.name && <IonNote slot='error'>{errors.name}</IonNote>}
                    </IonItem>
                    <IonItem className={errors.phone && touched.phone ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Phone</IonLabel>
                        <IonInput name='phone' placeholder='01XXXXXXXXX (BD Mobile Number Only)' min={1000000000} max={1999999999}
                            onIonChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone} />
                        {errors.phone && touched.phone && <IonNote slot='error'>{errors.phone}</IonNote>}
                    </IonItem>
                    <IonItem className={errors.email && touched.email ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput name='email' min={1000000000} max={1999999999}
                            onIonChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email} />
                        {errors.email && touched.email && <IonNote slot='error'>{errors.email}</IonNote>}
                    </IonItem>
                    <IonItem className={errors.password && touched.password ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput name='password' type='password' min={1000000000} max={1999999999}
                            onIonChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password} />
                        {errors.password && touched.password && <IonNote slot='error'>{errors.password}</IonNote>}
                    </IonItem>
                    <IonItem className={errors.confirmPassword && touched.confirmPassword ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Confirm Password</IonLabel>
                        <IonInput name='confirmPassword' type='password' min={1000000000} max={1999999999}
                            onIonChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword} />
                        {errors.confirmPassword && touched.confirmPassword && <IonNote slot='error'>{errors.confirmPassword}</IonNote>}
                    </IonItem>
                    {!!reCaptchaToken && <IonButton disabled={isSubmitting} type='submit' expand="block" color={authData.role_id == 2 ? "primary" : "success"}>{
                        isSubmitting ?
                            <IonSpinner /> :
                            "Sign Up"
                    }</IonButton>}
                </form>
            )}
        </Formik>
        {!reCaptchaToken && <div style={{ display: "flex", justifyContent: "center", padding: '10px 0 0' }}>
            <GoogleReCaptcha
                onVerify={onUpdateReCaptcha}
            />
        </div>}
    </div>
    if (authData.onlyForm) {
        return formBody
    }
    return (
        <>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>{authData.role_id == 2 ? "Reseller" : "Customer"} Sign Up</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                {formBody}
            </IonContent>
            <Footer />
        </>
    );
};

export default Form;
